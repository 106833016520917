import * as Yup from 'yup'
import { ToTwoDecimal } from '../utils/dataValidations'

export const UpdateCuadernoEfectivoValues = (cuadernoEf) => {
    let billetes = parseFloat(cuadernoEf.billetes)
    let monedas = parseFloat(cuadernoEf.monedas)
    let dolares = parseFloat(cuadernoEf.dolares)
    let totalAmount = billetes + monedas + dolares
    return {
        initialValues: {
            cuaderno_efectivo_id: cuadernoEf.id,
            monto_total: parseFloat(cuadernoEf?.monto_total) || 0,
            billetes: totalAmount === 0 ? parseFloat(cuadernoEf?.monto_total) : parseFloat(cuadernoEf.billetes),
            monedas: parseFloat(cuadernoEf?.monedas) || 0,
            dolares: parseFloat(cuadernoEf?.dolares) || 0,
            tipo_cambio: parseFloat(cuadernoEf?.tipo_cambio) || 6.96,
            dolares_en_bs: parseFloat(cuadernoEf?.dolares_en_bs) || 0,
            obs: cuadernoEf.obs || '',
        },
        fieldsValidation: Yup.object().shape({
            // almacen_producto_id: StringRequired,
            // precio_unitario: NumberRequired,
            // cantidad: NumberIntegerMinOneRequired,
        }),
        fields: [
            {
                label: 'Monto total',
                name: 'monto_total',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20'
            },
            // {
            //     label: 'Efectivo entregado',
            //     name: 'efectivo_entregado',
            //     type: 'groupnumberCalculator',
            //     subData: 'Bs.',
            //     disabled: true,
            //     highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20'
            // },
            // {
            //     label: 'Efectivo sobrante',
            //     name: 'efectivo_sobrante',
            //     type: 'groupnumberCalculator',
            //     subData: 'Bs.',
            //     disabled: true,
            //     highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20'
            // },
            // {
            //     label: 'Efectivo faltante',
            //     name: 'efectivo_faltante',
            //     type: 'groupnumberCalculator',
            //     subData: 'Bs.',
            //     disabled: true,
            //     highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20'
            // },
            {
                label: 'Dolares en Bs.',
                name: 'dolares_en_bs',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                disabled: true,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Billetes',
                name: 'billetes',
                type: 'groupnumberCalculator',
                subData: 'Sus.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const billetes = e.target.valueAsNumber || 0
                    const monto_total = values.monto_total || 0
                    const monedas = ToTwoDecimal(monto_total - billetes)
                    setValues('dolares', 0)
                    setValues('dolares_en_bs', 0)
                    setValues('monedas', monedas)
                },
            },
            {
                label: 'Monedas',
                name: 'monedas',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const monedas = e.target.valueAsNumber || 0
                    const monto_total = values.monto_total || 0
                    const billetes = ToTwoDecimal(monto_total - monedas)
                    setValues('dolares', 0)
                    setValues('dolares_en_bs', 0)
                    setValues('billetes', billetes)
                },
            },
            {
                label: 'Dolares',
                name: 'dolares',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const dolares = e.target.valueAsNumber || 0
                    const tipo_cambio = values.tipo_cambio || 0
                    const monto_total = values.monto_total || 0
                    const monedas = values.monedas || 0
                    const dolares_en_bs = dolares * tipo_cambio
                    const billetestotal = ToTwoDecimal(monto_total - (dolares_en_bs + monedas))

                    setValues('dolares_en_bs', dolares_en_bs)
                    setValues('billetes', billetestotal)
                },
            },
            {
                label: 'Tipo de cambio',
                name: 'tipo_cambio',
                type: 'groupnumberCalculator',
                subData: 'Bs.',
                required: true,
                ChangeCalculator: (values, setValues, e) => {
                    const tipo_cambio = e.target.valueAsNumber || 0
                    const dolares = values.dolares || 0
                    const monto_total = values.monto_total || 0
                    const monedas = values.monedas || 0
                    const dolares_en_bs = dolares * tipo_cambio
                    const billetestotal = ToTwoDecimal(monto_total - (dolares_en_bs + monedas))

                    setValues('dolares_en_bs', dolares_en_bs)
                    setValues('billetes', billetestotal)
                },
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}