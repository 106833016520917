import React from 'react'
import View from './View'
import Text from './Text'

export const TitleTable = ({
    textTitle,
    margin = '3px 0px 0px 0px',
    border = { top: true, right: true, left: true },
    style = { backgroundColor: 'rgba(30,95,160,.2)', letterSpacing: '2px' },
    padding = '1px 0px',

}) => {
    return (
        <View justifyContent='center' width='100%' border={border} style={style} margin={margin}>
            <Text padding={padding} bold textAlign='center'>{textTitle}</Text>
        </View>
    )
}

export const TitleTable2 = ({
    textTitle,
    margin = '3px 0px 0px 0px',
    border = { top: true, right: true, left: true },
    style = { backgroundColor: 'rgba(30,95,160,.2)', letterSpacing: '2px' },
    padding = '1px 0px',
    fontSize = null

}) => {
    return (
        <View justifyContent='center' width='100%' border={border} style={style} margin={margin}>
            <Text fontSize={fontSize} padding={padding} bold textAlign='center'>{textTitle}</Text>
        </View>
    )
}
