import * as Yup from 'yup'

export const decimalVerification = (value) => {
    return /^\d+(\.\d{1})?$/.test(value)
}

export const DecimalValidation = Yup.number()
    .required('Campo requerido')
    .positive('La cantidad debe ser mayor que 0')
    .moreThan(-0.1, 'La cantidad debe ser mayor o igual a 0')
    .test('solo-un-decimal', 'Debe tener solo un dígito decimal', (value) => decimalVerification(value))

export const DecimalValidationMoreThanZero = Yup.number()
    .required('Campo requerido')
    .positive('La cantidad debe ser mayor que 0')
    .moreThan(0, 'La cantidad debe ser mayor que 0')
    .test('solo-un-decimal', 'Debe tener solo un dígito decimal', (value) => decimalVerification(value))

export const ToOneDecimal = (val) => {
    const newVal = parseFloat(val.toFixed(1))
    return newVal
}

export function JoinStrings(lista, caracter) {
    const filterString = lista.filter(cadena => cadena !== null && cadena !== undefined && cadena !== '')
    return filterString.join(caracter)
}

export const ToTwoDecimal = (val) => {
    const newVal = parseFloat(val.toFixed(2))
    return newVal
}

export const NumberIntegerRequired = Yup.number()
    .required('Campo requerido')
    .integer('Debe ser un número entero')
    .min(0, 'Debe ser mayor o igual a 0')

export const NumberRequired = Yup.number()
    .required('Campo requerido')
    .min(1, 'Debe ser mayor o igual a 0')

export const NumberIntegerMinOneRequired = Yup.number()
    .required('Campo requerido')
    .min(1, 'Debe ser mayor o igual a 1')

export const AlmacenTotalValidation = Yup.number()
    .required('Campo requerido')
    .min(1, 'Cantidad de producto insuficiente!')

export const AlmacenTotalRestanteValidation = Yup.number()
    .required('Campo requerido')
    .min(0, 'Cantidad de producto insuficiente REST!')

export const StringRequired = Yup.string()
    .required('Campo requerido')

export function listToString(lista, separador) {
    const listaFiltrada = lista.filter(item => item !== null && item !== undefined && item !== '');
    return listaFiltrada.join(separador);
}