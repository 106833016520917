import * as Yup from 'yup';

export const solicitarCompraValues = (almacen_id) => {
    return {
        initialValues: {
            almacen_id: almacen_id || '',
            // producto_id: '',
            // estado_id: '',
            productos: []
        },
        fieldsValidation: Yup.object().shape({}),
        fields: [
            {
                label: 'Seleccionar producto',
                name: 'productos',
                type: 'solicitarCompra',
                required: false,
                urlApi: `/almacenProductos/${almacen_id}/all`,
                indexLabel: 'etiqueta',
                indexId: 'id',
                // generalData: data,
                title: 'COMPRA DE PRODUCTOS'
            },
        ]
    }
}

export const CompraProductosPaso2 = (proveedores, defaultValue, efectivoOptions, defaultEfectivo) => {
    return {
        fields: [
            {
                label: 'Monto total',
                name: 'monto',
                type: 'groupnumber',
                subData: 'Bs.',
                required: true,
                disabled: false,
                highlight: 'bg-blue-600 dark:bg-blue-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Proveedor',
                name: 'almacen_proveedor_id',
                type: 'selectSearchStatic',
                // urlApi: '/proveedores',
                required: true,
                labelDescription: 'nombre',
                placeholder: 'Proveedor...',
                autoFocus: false,
                data: proveedores,
                defaultValue: defaultValue
            },
            {
                label: 'Entidad',
                name: 'efectivo_id',
                type: 'selectSearchStatic',
                // urlApi: '/proveedores',
                required: true,
                labelDescription: 'nombre',
                placeholder: 'Entidad...',
                autoFocus: false,
                data: efectivoOptions,
                defaultValue: defaultEfectivo
            },
        ]
    }
}

export const CompraProductosPaso3 = (transportistas, defaultValue) => {
    return {
        fields: [
            {
                label: 'Transportista',
                name: 'transportista_id',
                type: 'selectSearchStatic',
                // urlApi: '/transportistas',
                required: true,
                labelDescription: 'nombre',
                placeholder: 'Transportista...',
                autoFocus: false,
                data: transportistas,
                defaultValue: defaultValue
            },
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}