import React, { useState } from 'react'
import { RequiredPick } from './ModalFormField'
import { ModalTableForm } from './ModalTableForm'

export const SelectTable = ({
    field,
}) => {
    const [item, setItem] = useState(null)
    const [openModal, setOpenModal] = useState(false)

    return (
        <>
            <div className='col-span-6 sm:col-span-4 md:col-span-3' >
                <label htmlFor={field.name} className='block mb-2 text-sm font-semibold text-gray-900 dark:text-white'>
                    {field.label}
                    {field.required && <RequiredPick />}
                </label>
                <div className='flex'>
                    <button
                        type={'button'}
                        onClick={() => setOpenModal(true)}
                        className={`shadow-sm outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-semibold disabled:opacity-55 disabled:text-gray-500 dark:disabled:text-gray-400 ${field.highlight}`}
                    >
                        {
                            item ?
                                item.value
                                :
                                <span className='text-gray-400'>Seleccionar...</span>
                        }
                    </button>
                    <i
                        className='fa-solid fa-close inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-gray-300 rounded-r-lg dark:bg-gray-600 dark:text-gray-300 dark:hover:text-gray-200 dark:border-gray-500 cursor-pointer'
                        onClick={() => setItem(null)}
                    />
                </div>
            </div>
            {
                openModal &&
                <ModalTableForm
                    setModal={setOpenModal}
                    item={item}
                    setItem={setItem}
                    field={field}
                />
            }
        </>
    )
}