import * as Yup from 'yup';
import { formatInputDate } from '../utils/dateFormat';

export const CreateValues = (almacen_producto_id) => {
    return {
        initialValues: {
            almacen_producto_id: almacen_producto_id || '',
            nombre: '',
            fecha_inicio: formatInputDate(new Date()),
            fecha_fin: formatInputDate(new Date()),
            // ingreso: false,
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                label: 'Nombre',
                name: 'nombre',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Fecha de inicio',
                name: 'fecha_inicio',
                type: 'date',
                required: true,
                placeholder: 'Fecha de inicio...',
                autoFocus: false
            },
            {
                label: 'Fecha fin',
                name: 'fecha_fin',
                type: 'date',
                required: true,
                placeholder: 'Fecha fin...',
                autoFocus: false
            },
        ],

    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}