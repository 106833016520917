import { View as ViewPdf } from '@react-pdf/renderer'

const View = (props) => {
  const {
    display = 'flex',
    flexDirection = 'row',
    border,
    borderContent,
    children,
    width,
    alignItems,
    justifyContent,
    padding,
    margin,
    style,
  } = props

  const borderStyle = {
    borderTop: border?.top || borderContent ? '1px solid rgba(30,95,160,1)' : '',
    borderRight: border?.right || borderContent ? '1px solid rgba(30,95,160,1)' : '',
    borderBottom: border?.bottom || borderContent ? '1px solid rgba(30,95,160,1)' : '',
    borderLeft: border?.left || borderContent ? '1px solid rgba(30,95,160,1)' : '',
  }

  const styleView = {
    ...borderStyle,
    flexDirection: flexDirection,
    width: width,
    display: display,
    alignItems: alignItems,
    justifyContent: justifyContent,
    padding: padding,
    margin: margin,
    ...style,
  }

  return <ViewPdf style={styleView}>{children}</ViewPdf>
}

export default View
