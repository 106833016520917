import React from 'react'
import { ModalContainer } from './ModalContainer';
import { ModalHeader } from './ModalHeader';
export const ModalStep = ({
    setModal,
    label,
    dataValues,
    children
}) => {
    return (
        <ModalContainer
            setModal={setModal}
            width={'md:max-w-5xl'}
        >
            <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
                <ModalHeader
                    setModal={setModal}
                    label={label}
                />
                {children}
            </div>
        </ModalContainer>
    )
}