import React from 'react'
import { ModalContainer } from './ModalContainer'
import { ModalHeader } from './ModalHeader'
// import { ModalFooter } from './ModalFooter'
// import { ModalFormBody } from './ModalFormBody'
import { ModalTableBody } from './ModalTableBody'

export const ModalTableForm = ({
    setModal,
    width = 'md:max-w-5xl',
    item,
    setItem,
    field
}) => {
    return (
        <ModalContainer
            setModal={setModal}
            width={width}
        >
            <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
                <ModalHeader
                    setModal={setModal}
                    label={'asdasd'}
                />
                <ModalBody>
                    <ModalTableBody
                        item={item}
                        setItem={setItem}
                        field={field}
                    />
                </ModalBody>
            </div>
        </ModalContainer>
    )
}

const ModalBody = ({ children }) => {
    return (
        <div className='m-2'>
            {children}
        </div>
    )
}